import { device } from 'src/styles/breakpoints'
import { orange, graphite } from 'src/styles/newColors'
import { white } from 'src/styles/colors'
import { rounded } from 'src/styles/rounded'

import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${orange.clay};

  @media ${device.tablet} {
    height: 466px;
    background-color: transparent;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-auto-dobra7/image.webp');
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${device.desktopLG} {
    height: 583px;
  }

  @media ${device.desktopXL} {
    height: 820px;
  }

  h3 {
    font-size: 12px;
    line-height: 14px;
    font-family: "Inter";

    @media ${device.tablet} {
      font-size: 9px;
      line-height: 11px;
    }

    @media ${device.desktopLG} {
      font-size: 12px;
      line-height: 15px;
    }

    @media ${device.desktopXL} {
      font-size: 18px;
      line-height: 22px;
    }
  }

  button {
    @media ${device.tablet} {
      max-width: 100%;
    }

    @media ${device.desktopLG} {
      max-width: 322px;
    }

    @media ${device.desktopXL} {
      max-width: 454px;
    }
  }

  .box {
    background-color: ${white};
    border-radius: ${rounded['2']};

    @media ${device.tablet} {
      background-color: transparent;
      border-radius: none;
    }
  }

  .content {
    @media ${device.tablet} {
      background-color: ${white};
      border-radius: ${rounded['2']};
    }
  }
`

export const CardWrapper = styled.div`
  @media ${device.tablet} {
    margin: 0 auto;
  }

  p {
    font-size: 12px;
    line-height: 14px;

    @media ${device.tablet} {
      font-size: 9px;
      line-height: 10px;
    }

    @media ${device.desktopLG} {
      font-size: 12px;
      line-height: 15px;
    }

    @media ${device.desktopXL} {
      font-size: 18px;
      line-height: 22px;
    }
  }
`
