import { device } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: #943D15;
  height: 359px;

  button {
    border: 1px solid ${white};
  }

  @media ${device.tablet} {
    height: 374px;
  }

  @media ${device.desktopLG} {
    height: 500px;
  }

  @media ${device.desktopXL} {
    height: 722px;
  }
`
