import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import ImageWebp from 'src/components/ImageWebp'
import ScrollTo from 'src/components/ScrollTo'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const Hero = () => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section role='img' aria-label='Empreendedores sorrindo enquanto olham para um notebook.' className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6'>
            <h3 className='fs-18 fs-md-18 fs-lg-28 lh-33 lh-md-28 lh-lg-36 text-white fw-700 font-citrina mb-md-1'>
              Pix Automático
            </h3>
            <h1 className='fs-20 fs-md-24 fs-lg-40 fs-xl-48 lh-25 lh-md-28 lh-lg-44 lh-xl-52 text-white fw-700 font-citrina'>
              Automatize seus recebimentos recorrentes através do Pix automático.
            </h1>
            <p className='fs-12 fs-xl-18 lh-12 lh-xl-18 text-white fw-400 mb-md-2 mb-xl-3'>
              Seu cliente sem preocupações e seu caixa movimentado.
            </p>
            <a
              href='https://conta-digital-pj.inter.co/login?'
              target='_blank'
              className='btn btn--lg btn-orange--extra text-white text-none w-100 mw-100 mt-3'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click button',
                  section_name: 'Automatize seus recebimentos recorrentes através do Pix automático.',
                  element_name: 'Abrir conta PJ gratuita',
                })
              }}
            >
              Abrir conta PJ gratuita
            </a>
            <ScrollTo
              to='#pix-automatico'
              title='Saber mais'
              className='btn btn--outline btn--lg btn--white text-white rounded-2 fs-14 text-none mt-3 w-100 mw-100'
              section='dobra_01'
              sectionName='Automatize seus recebimentos recorrentes através do Pix automático.'
              elementName='Saber mais'
            >
              <button className='btn--lg bg-transparent btn-outline text-white rounded-3 w-100 fs-14 fw-600 text-none mt-3'>
                Saiba mais
              </button>
            </ScrollTo>
          </div>
          {width >= WIDTH_MD && 
            <div className='col-md-6 col-lg-5 offset-lg-1 d-flex justify-content-md-end'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-auto-hero/image.webp'
                altDescription='Empreendedores sorrindo enquanto olham para um notebook.'
                arrayNumbers={[ 281, 281, 376, 440 ]}
                arrayNumbersHeight={[ 281, 281, 376, 440]}
              />
            </div>
          }
        </div>
      </div>
    </Section>
  )
}

export default Hero
