import { device } from 'src/styles/breakpoints'
import { orange, sand, graphite } from 'src/styles/newColors'
import { rounded } from 'src/styles/rounded'

import styled from 'styled-components'

export const Section = styled.section`

  @media ${device.tablet} {
    height: 829px;
  }

  @media ${device.desktopLG} {
    height: 899px;
  }

  @media ${device.desktopXL} {
    height: 1200px;
  }

  button {
    @media ${device.tablet} {
      width: 283px;
    }

    @media ${device.desktopLG} {
      width: 377px;
    }

    @media ${device.desktopXL} {
      width: 531px;
    }
  }
`

export const Pix = styled.div`
  background-color: ${orange.clay};
  border-radius: ${rounded['6']};

  @media ${device.desktopXL} {
    height: 300px;
  }

  .icon {
    width: 16px;
    height: 16px;
  }

  p {
    font-size: 8px;
    line-height: 9px;

    @media ${device.desktopLG} {
      font-size: 10px;
      line-height: 12px;
    }

    @media ${device.desktopXL} {
      font-size: 14px;
      line-height: 17px;
    }
  }
`

export const Debit = styled.div`
  background-color: ${sand};
  border-radius: ${rounded['6']};

  @media ${device.desktopXL} {
    height: 300px;
  }

  h4 {
    color: #9A4517;
  }

  img {
    width: 16px;
    height: 16px;
  }

  p {
    font-size: 8px;
    line-height: 9px;
    color: ${graphite};

    @media ${device.desktopLG} {
      font-size: 10px;
      line-height: 12px;
    }

    @media ${device.desktopXL} {
      font-size: 14px;
      line-height: 17px;
    }
  }
  `