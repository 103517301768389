import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { WIDTH_MD } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import ContractArrowUp from '../../assets/images/contract-arrow-up.png'
import MoneyEntry from '../../assets/images/money-entry-brown.png'

import { Section, Card } from './style'

const AdvantagesInterIntegration = () => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section
      role='img'
      aria-label='Homem atento olhando para um notebook.' className='py-5 d-flex align-items-center'
    >
      <div className='container'>
        {width < WIDTH_MD && <div className='row align-items-md-center'>
          <div className='col-12 col-md-5 offset-xl-1 d-flex justify-content-center justify-content-md-end'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-auto-dobra6-360/image.webp'
              altDescription='Homem atento olhando para um notebook.'
              arrayNumbers={[ 315 ]}
              arrayNumbersHeight={[ 311 ]}
            />
          </div>
        </div>}
        <div className='col-12 col-md-6 mt-5 mt-md-0'>
          <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-28 lh-lg-44 lh-xl-40 text-graphite fw-700 font-citrina mb-4 mb-md-2'>
            Quais são as vantagens de integrar com o Inter?
          </h2>
          <p className='text-grayscale--400'>Através das APIs, a sua empresa poderá criar aplicações que se comunicam diretamente com o Inter, sem a necessidade de acessar o Internet Banking ou o Super App. Isso significa que você pode conectar o seu ERP e ter uma experiência completa. E não para por aí, ainda aproveita:</p>
          <div className='row'>
            <div className='col-6 mb-2'>
              <Card className='my-2 my-md-0'>
                <div className='d-flex align-items-center'>
                  <div className='mr-2'><OrangeIcon size='SM' color='#9A4517' icon='list-check' /></div>
                  <div><p className='text-grayscale--400 fw-600 mb-0'>Sistemas otimizados</p></div>
                </div>
              </Card>
            </div>
            <div className='col-6 mb-2'>  
              <Card className='my-2 my-md-0'>
                <div className='d-flex align-items-center'>
                  <div className='mr-2'><OrangeIcon size='SM' color='#9A4517' icon='check-circle' /></div>
                  <div><p className='text-grayscale--400 fw-600 mb-0'>Plataforma intuitiva</p></div>
                </div>
              </Card>
              </div>
              <div className='col-6 mb-2'>  
              <Card className='my-2 my-md-0'>
                <div className='d-flex align-items-center'>
                  <div className='mr-2'><img className='icon' src={MoneyEntry} /></div>
                  <div><p className='text-grayscale--400 fw-600 mb-0'>Taxas competitivas</p></div>
                </div>
              </Card>
              </div>
              <div className='col-6 mb-2'>  
              <Card className='my-2 my-md-0'>
                <div className='d-flex align-items-center'>
                  <div className='mr-2'><img className='icon' src={ContractArrowUp} /></div>
                  <div><p className='text-grayscale--400 fw-600 mb-0'>Melhorias contínuas</p></div>
                </div>
              </Card>
              </div>
              <div className='col-6 mb-2'>  
              <Card className='my-2 my-md-0'>
                <div className='d-flex align-items-center'>
                  <div className='mr-2'><OrangeIcon size='SM' color='#9A4517' icon='services' /></div>
                  <div><p className='text-grayscale--400 fw-600 mb-0'>Time de suporte</p></div>
                </div>
              </Card>
            </div>
            <a
              href='https://conta-digital-pj.inter.co/login?'
              target='_blank'
              className='btn btn--lg btn-orange--extra text-white text-none mt-2'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  element_action: 'click button',
                  section_name: 'Quais são as vantagens de integrar com o Inter?',
                  element_name: 'Abrir conta PJ gratuita',
                })
              }}
            >
              Abrir conta PJ gratuita
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AdvantagesInterIntegration
