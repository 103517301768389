import { device } from 'src/styles/breakpoints'
import { sand, graphite } from 'src/styles/newColors'
import { white } from 'src/styles/colors'
import { rounded } from 'src/styles/rounded'

import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${sand};

  @media ${device.tablet} {
    height: 437px;
    background-color: transparent;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-auto-dobra5-768/image.webp');
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${device.desktopLG} {
    height: 583px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-auto-dobra5-1024/image.webp');
  }

  @media ${device.desktopXL} {
    height: 820px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-auto-dobra5-1440/image.webp');
  }

  h2 {
    color: ${graphite};

    @media ${device.tablet} {
      color: ${white};
    }
  }

  button {
    @media ${device.tablet} {
      width: 251px;
    }

    @media ${device.desktopLG} {
      width: 334px;
    }

    @media ${device.desktopXL} {
      width: 471px;
    }
  }

  img {
    width: 16px;
    height: 16px;
  }
`

export const CardWrapper = styled.div`
  @media ${device.tablet} {
    margin: 0 auto;
  }
`

export const Card = styled.div`
  @media ${device.tablet} {
    background-color: ${white};
    border-radius: ${rounded['2']};
    height: 176px;
  }

  @media ${device.desktopLG} {
    height: 226px;
  }

  @media ${device.desktopXL} {
    height: 285px;
  }

  p {
    font-size: 9px;
    line-height: 11px;

    @media ${device.desktopLG} {
      font-size: 12px;
      line-height: 14px;
    }

    @media ${device.desktopXL} {
      font-size: 16px;
      line-height: 20px;
    }
  }

  h3 {
    font-size: 12px;
    line-height: 14px;

    @media ${device.tablet} {
      font-size: 10px;
      line-height: 12px;
    }

    @media ${device.desktopLG} {
      font-size: 13px;
      line-height: 15px;
    }

    @media ${device.desktopXL} {
      font-size: 18px;
      line-height: 22px;
    }
  }
`