import { device } from 'src/styles/breakpoints'
import { sand } from 'src/styles/newColors'
import { rounded } from 'src/styles/rounded'

import styled from 'styled-components'

export const Section = styled.section`
  
  @media ${device.tablet} {
    height: 395px;
  }

  @media ${device.desktopLG} {
    height: 526px;
  }

  @media ${device.desktopXL} {
    height: 741px;
  }

  .icon {
    width: 22px;
    height: 22px;
  }
`

export const Card = styled.div`
  background-color: ${sand};
  border-radius: ${rounded['2']};

  p {
    font-size: 9px;
    line-height: 10px;

    @media ${device.desktopLG} {
      font-size: 11px;
      line-height: 14px;
    }

    @media ${device.desktopXL} {
      font-size: 16px;
      line-height: 20px;
    }
  }
`