import { device } from 'src/styles/breakpoints'
import { sand } from 'src/styles/newColors'

import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${sand};

  .icon {
    width: 16px;
    height: 16px;
  }
  
  @media ${device.tablet} {
    max-height: 295px;
  }

  @media ${device.desktopLG} {
    max-height: 398px;
  }

  @media ${device.desktopXL} {
    max-height: 554px;
  }
`