import React, { useState } from 'react'
import { Wrapper } from './style'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import useUtms from 'src/hooks/useUtms'

import Hero from './sections/hero/_index'
import AutomaticPix from './sections/automatic-pix/_index'
import WhatChanges from './sections/what-changes/_index'
import TypesOfBusiness from './sections/types-of-business/_index'
import AdvantagesToReceiveInter from './sections/advantages-to-receive-inter/_index'
import AdvantagesInterIntegration from './sections/advantages-inter-integration/_index'
import YourCompanyProfits from './sections/your-company-profits/_index'
import NewContactForm from './components/ContactForm/_newContactForm'

function SpreadsheetMei () {
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const domReady = useDomReady()

  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }

  const openModal = domReady && (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      locationToRender={document.body}
    >
      <NewContactForm
        closeModal={() => setIsModalOpen(false)}
      />

    </Modal>
  )

  return (
    <Wrapper>
      {openModal}
      <Layout pageContext={pageContext} handleHeaderOpenAccount={() => openAccountPJFormModal()}>
        <Hero />
        <AutomaticPix />
        <WhatChanges />
        <TypesOfBusiness />
        <AdvantagesToReceiveInter setModalOpen={setIsModalOpen} />
        <AdvantagesInterIntegration />
        <YourCompanyProfits />
      </Layout>
    </Wrapper>
  )
}

export default SpreadsheetMei
