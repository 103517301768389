import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.section`
  
  @media ${device.tablet} {
    height: 462px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-auto-dobra6/image.webp');
    background-repeat: no-repeat;
    background-position: 390px 0;
    background-size: contain;
  }

  @media ${device.desktopLG} {
    height: 617px;
    background-position: 513px 0;
  }

  @media ${device.desktopXL} {
    height: 779px;
    background-position: top right;
  }

  a {
    @media ${device.tablet} {
      max-width: 471px;
    }

    @media ${device.desktopLG} {
      max-width: 418px;
    }
  }

  p {
    font-size: 12px;
    line-height: 14px;

    @media ${device.tablet} {
      font-size: 9px;
      line-height: 11px;
    }

    @media ${device.desktopLG} {
      font-size: 12px;
      line-height: 15px;
    }

    @media ${device.desktopXL} {
      font-size: 15px;
      line-height: 18px;
    }
  }

  .icon {
    width: 16px;
    height: 16px;
  }
`

export const Card = styled.div`
  p {
    font-size: 12px;
    line-height: 15px;

    @media ${device.tablet} {
      font-size: 9px;
      line-height: 11px;
    }

    @media ${device.desktopLG} {
      font-size: 12px;
      line-height: 15px;
    }

    @media ${device.desktopXL} {
      font-size: 16px;
      line-height: 19px;
    }
  }
`