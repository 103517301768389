import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Section } from './style'
import RotateCheck from '../../assets/images/rotate-check.png'

const WhatChanges = () => {

  return (
    <Section id='o-que-muda' className='pt-5 py-md-5 py-lg-0 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-5 col-xl-6 offset-lg-1 offset-xl-0 order-md-last'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-28 lh-lg-44 text-graphite fw-700 font-citrina mb-3 mb-md-3'>
              O que muda pra você e pro seu negócio?
            </h2>
            <div className='d-flex align-items-center mb-md-2'>
              <div className='mr-2'><OrangeIcon size='SM' color='#9A4517' icon='investments' /></div>
              <div><p className='fs-12 fs-xl-18 lh-14 lh-xl-22 text-grayscale--400 mb-0'>Oportunidade de expandir sua base de clientes</p></div>
            </div>
            <div className='d-flex align-items-center mb-md-2'>
              <div className='mr-2'><OrangeIcon size='SM' color='#9A4517' icon='computer' /></div>
              <div><p className='fs-12 fs-xl-18 lh-14 lh-xl-22 text-grayscale--400 mb-0'>Experiência de pagamento mais moderna e conveniente</p></div>
            </div>
            <div className='d-flex align-items-center mb-md-2'>
              <div className='mr-2'><OrangeIcon size='SM' color='#9A4517' icon='no-taxes' /></div>
              <div><p className='fs-12 fs-xl-18 lh-14 lh-xl-22 text-grayscale--400 mb-0'>Custo reduzido do Pix em comparação com outras soluções de pagamento</p></div>
            </div>
            <div className='d-flex align-items-center'>
              <div className='mr-2'><img className='icon' src={RotateCheck} /></div>
              <div><p className='fs-12 fs-xl-18 lh-14 lh-xl-22 text-grayscale--400 mb-0'>Automação das suas cobranças recorrentes</p></div>
            </div>
          </div>
          <div className='col-md-5 col-lg-4 col-xl-6 offset-md-1 offset-xl-0 d-flex justify-content-center justify-content-md-start'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-auto-dobra3/image.webp'
              altDescription='Home do Super App Inter empresas.'
              arrayNumbers={[ 210, 210, 283, 394 ]}
              arrayNumbersHeight={[ 262, 295, 398, 554]}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default WhatChanges
